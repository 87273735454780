const nfResize = require("./nfResize")
const ucResize = require("./ucResize")

function isUploadCare(mediaUrl = "") {
  return mediaUrl.indexOf("https://ucarecdn.com/") > -1
}

function fit(mediaUrl, width = 300, height = "", format = "") {
  return isUploadCare(mediaUrl)
    ? ucResize.fit.apply(null, arguments)
    : nfResize.fit.apply(null, arguments)
}

function smartCrop(mediaUrl, width = 300, height = 300, format = "") {
  return isUploadCare(mediaUrl)
    ? ucResize.smartCrop.apply(null, arguments)
    : nfResize.smartCrop.apply(null, arguments)
}

module.exports = {
  fit,
  smartCrop,
}
