import React from "react"
import Reveal from "react-reveal/Reveal"

import "./styles.less"
import styles from "./styles.module.less"

import { fit } from "util/imgResize"

const Thoughts = ({ frontmatter, html }) => {
  const { title, heroImage, date, author, description } = frontmatter
  const heroStyles = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.6) 80%), url(${fit(
      heroImage,
      1440,
      "",
      "jpeg"
    )})`,
  }
  return (
    <>
      <Reveal>
        <header className={styles.Hero} style={heroStyles}>
          <div className={styles.wrap}>
            <h1>{title}</h1>
            {author ? <h2>By {author}</h2> : ""}
            <h2>{description}</h2>
            <h2>{date}</h2>
          </div>
        </header>
      </Reveal>
      <section className={styles.Thoughts}>
        <article className={styles.article}>
          <Body html={html} />
        </article>
      </section>
    </>
  )
}

export default Thoughts

const Body = ({ html }) => {
  if (typeof html === "string") {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className={styles.markdown}
      />
    )
  } else {
    return <div className={styles.markdown}>{html}</div>
  }
}
