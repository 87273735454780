import React from "react"
import { graphql } from "gatsby"

import Thoughts from "../components/Thoughts"
import Layout from "../components/Layout"

export default function Template({ data }) {
  const post = data.markdownRemark // data.markdownRemark holds your post data

  return (
    <div>
      <Layout
        backgroundColor={"white"}
        navColor={"black"}
        bannerBgColor=""
        isArchive
      >
        <Thoughts {...post} />
      </Layout>
    </div>
  )
}
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        heroImage
        previewImage
      }
    }
  }
`
