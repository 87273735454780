/**
 * Netlify Large Media transforms
 *
 * Resize images (crop or fit) with URL parameters.
 *
 * Tracked media files live in static/media
 *
 * example smart crop URL
 * https://develop--thesis-web.netlify.com/media/background.jpg?nf_resize=smartcrop&w=373&h=609
 *
 * example fit URL
 * https://develop--thesis-web.netlify.com/media/background.jpg?nf_resize=fit&w=373&h=609
 */

const productionBranch = "master"

/**
 * Return the current host based upon the build environment.
 * If "production" (master branch) use the configured URL
 * else use the current deploy URL
 *
 * These values should be stubbed in .env.development
 */
const getHost = () => {
  const isProduction = process.env.BRANCH === productionBranch
  return isProduction ? process.env.URL : process.env.DEPLOY_URL
}

/**
 * Create an image URL to resize to fit within the widgh and height
 * Image will _not_ be cropped
 * @param {String} mediaUrl
 * @param {Number} width
 * @param {Number} height
 */
function fit(mediaUrl, width = 300, height) {
  let w = encodeURIComponent(width)
  let h = encodeURIComponent(height || "")
  return `${getHost()}${mediaUrl}?nf_resize=fit&w=${w}&h=${h}`
}

/**
 * Create an image URL smart-crop to exactly fit the provided width and height
 * Image _will_ be cropped
 * @param {String} mediaUrl
 * @param {Number} width
 * @param {Number} height
 */
function smartCrop(mediaUrl, width = 300, height = 300) {
  let w = encodeURIComponent(width)
  let h = encodeURIComponent(height)
  return `${getHost()}${mediaUrl}?nf_resize=fit&w=${w}&h=${h}`
}

module.exports = {
  fit,
  smartCrop,
}
