const formats = ["jpeg", "png", "webp", "auto"]

/**
 * Create an image URL to resize to fit within the widgh and height
 * Image will _not_ be cropped
 * @param {String} mediaUrl
 * @param {Number} width
 * @param {Number} height
 */
function fit(mediaUrl, width = 300, height = "", format = "auto") {
  let fmt = formats.includes(format) ? format : "auto"
  let f = `-/format/${fmt}/`
  return `${mediaUrl}-/resize/${width}x${height}/${f}`
}

/**
 * Create an image URL smart-crop to exactly fit the provided width and height
 * Image _will_ be cropped
 * @param {String} mediaUrl
 * @param {Number} width
 * @param {Number} height
 */
function smartCrop(mediaUrl, width = 300, height = 300, format = "auto") {
  let fmt = formats.includes(format) ? format : "auto"
  let f = `-/format/${fmt}/`
  return `${mediaUrl}-/scale_crop/${width}x${height}/smart_faces_objects/${f}`
}

module.exports = {
  fit,
  smartCrop,
}
